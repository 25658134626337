import { render, staticRenderFns } from "./elementContent.vue?vue&type=template&id=586bed65&scoped=true"
import script from "./elementContent.vue?vue&type=script&lang=js"
export * from "./elementContent.vue?vue&type=script&lang=js"
import style0 from "./elementContent.vue?vue&type=style&index=0&id=586bed65&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586bed65",
  null
  
)

export default component.exports