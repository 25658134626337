<template>
    <div class="elementContent">
        <div class="table el_left">
            <div class="table-bg">
                <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%"
                >
                    <el-table-column
                        prop="address"
                        label="工程名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="工程名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="状态"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="部门名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="委托单位"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="工程部位"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="计划方量"
                    >
                    </el-table-column>
                </el-table>
                <div>
                    <div class="totalConsumption">
                        <div>
                            <p>每方总用量</p>
                            <span>
                                <strong>2830</strong>kg
                            </span>
                        </div>
                    </div>
                    <div class="totalAlkalinity">
                        <div>
                            <p>每方总碱量</p>
                            <span>
                                <strong>0.83</strong>kg
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="conclusion el_left">
            <div>
                <p>碱含量结论:</p>
                <textarea
                    placeholder="请在此处输入结论..."
                ></textarea>
            </div>
            <div>
                <p>氯含量结论:</p>
                <textarea
                    placeholder="请在此处输入结论..."
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 基础配比库——碱含量/氯离子含量 
    name: 'element-content',
    components: {},
    props: {},
    data() {
        return {
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄',
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄',
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄',
            }],
            radio: '',
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.elementContent
    height 100%
    .table
        width 9rem
        margin-right 0.2rem
        height 100%
        .table-bg
            border 1px solid #f0f0f0
            border-radius 8px
            .el-table
                border none
                height calc(100% - 1rem)
            >div
                width 100%
                height 1rem
                .totalConsumption,
                .totalAlkalinity
                    float left
                    width 50%
                    height 100%
                    div
                        width 3rem
                        height 0.58rem
                        border 1px solid #D7D7D7
                        margin 0.21rem auto
                        p,span
                            float left
                        p
                            width 1.2rem
                            font-size 0.18rem
                            line-height 0.56rem
                            text-align center
                            color #5588F1
                            background rgba(85, 136, 241, 0.1)
                        span
                            font-size 0.18rem
                            color #979EA7
                            line-height 0.56rem
                            width calc(100% - 1.2rem)
                            text-align center
                            strong
                                font-size 0.32rem
                                padding-right 0.05rem
                .totalConsumption
                    border-right 1px dashed #D7D7D7
                    div
                        span
                            strong
                                color #5588F1
                .totalAlkalinity
                    div
                        p
                            color #EB656F
                            background rgba(235, 101, 111, 0.1)
                        span
                            strong
                                color #EB656F
    .conclusion
        width calc(100% - 9.2rem)
        height 100%
        div
            width 100%
            height calc(50% - 0.1rem)
            overflow hidden
            &:nth-of-type(1)
                margin-bottom 0.2rem
            p,textarea
               float left
            p
               width 1rem
               font-size 0.16rem
               line-height 0.36rem
            textarea
                width calc(100% - 1rem)
                height 100%
                resize none
                border 1px solid #D7D7D7
                padding 0.1rem
                font-size 0.16rem
                line-height 0.22rem
                color #979EA7
</style>